
import store from '@/store'
import {postDC, postPaas, saveLogger} from "@/assets/utils/request"
import { getTimestampByDate} from "@/utils/time-zone";

import {checkPointPro} from "@/assets/utils/checkCouonType";
import {COUPON_RULES} from "./rules";
import {MEZZOFY_COUPON} from "./mezzofy";

/**
 * 优惠券字段
 * voucherType 优惠券类型
 * 1：现金券
 * 2: 折扣券
 * 3: 赠品券
 * 4: 满减券
 * 5: 一口价券
 *
 * voucherCalType 【 DC needType 】 门槛 0-无门槛  1-金额门槛 2-餐品门槛
 * includedBoxedMealFee 【 DC isContainMealFee 】 金额门槛-条件是否包含餐盒费
 * minUsage 门槛金额
 * pointsoftExclProducts  餐品门槛-例外餐品的餐品映射码 -- 若有值则为例外餐品门槛
 * minUsageProductCode 餐品门槛-指定餐品的餐品映射码
 *
 *
 * canDaoProductType  【 DC needProductType 】  餐道一口价优惠券-指定餐品类型   0-全部餐品 1-Single, 2-Combo
 * canDaoExclProductType 【 DC expProductType 】 餐道一口价优惠券-例外餐品类型  0-全部餐品 1-Single, 2-Combo
 *
 * canDaoFixPrice 【 DC fixPrice 】 888 = 8.88 餐道一口价优惠券-一口价价格
 * canDaoPriceType 【 DC priceType 】 金额门槛-餐品价格类型 0-优惠后餐品总金额；1-原价餐品总金额
 *
 *
 */

// 1.营销活动核心规则：【餐品折扣 → 减免指定餐品=第二件折扣 → 订单折扣=满减】 → 【赠品券、一口价餐品券、满减券、折扣券、餐品减免券】 → 【满加】 → 【满送】 → 【配送费优惠】 → 【现金券】
// 2.2.配送费优惠的门槛计算：普通餐品+营销活动+优惠券+满加+满送——判断是否满足配送费优惠


/**
 * 深拷贝
 * @param data
 * @return {any}
 */
const deepCopy = data => JSON.parse(JSON.stringify(data))

export const PRODUCT_MENU = {
  copy: []
}
export const setCopyMenu = (menu = []) => {
  PRODUCT_MENU.copy = menu
}

export const COUPON = {
  yuu: [], // yuu 券列表
  yuuResult: null, // yuu 優惠券接口返回的完整數據 -- 含自定義異常

  member: [], // 會員優惠券
  memberResult: null, // 會員優惠券接口返回的完整數據 -- 含自定義異常
  mezzofyList:[],//mezzofy券列表
  rules: [],
  mezzofyData:MEZZOFY_COUPON||{}
}

const resetCoupon = () => {
  COUPON.yuu = []
  COUPON.yuuResult = null
  COUPON.member = []
  COUPON.memberResult = null
  COUPON.mezzofyList = null
  COUPON.mezzofyData=MEZZOFY_COUPON||{}

}

// ------------------------------yuu券 START----------------------------------
const sleep = (time = 500) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  });
}
/**
 * 獲取yuu 是否已登陸
 * @return {Promise<boolean>}
 */
const getIsLoginYuu = async (reload= false) => {
  if (store.state.isYuu){
    return true
  }
  if (store.state?.userInfo?.userId) {
    const res = await yuuLogin(reload)
    return !!res
  }

  return false
}
/**
 * 獲取yuu登陸態
 * @return {Promise<*|null>}
 */
export const yuuLogin = async (reload = false) => {
  try {
    const { yuuTokenlogin } = store.state
    if (yuuTokenlogin && !reload) {
      return yuuTokenlogin
    }
    if (store.state.isYuu){
      return store.state?.yuuInfo?.yuuGuestKey || ''
    }

    const loginParams = {
      actionName: "candao.member.yuuLoginStatus",
      content: {
        userId: store.state?.userInfo?.userId||''
      }
    }
    const res = await postPaas('UserUnify', loginParams)
    if(res.status !== 1 || !res?.data?.yuuToken) {
      console.log('yuu 未登录  / yuu yuuToken error', res)
      store.commit("setYuuyuuToken", '')
      return null
    }

    const yuuToken = res.data.yuuToken
    store.commit("setYuuyuuToken", yuuToken)
    return yuuToken
  } catch (err) {
    console.error(err)
    store.commit("setYuuyuuToken", '')
    return null
  }
}
/**
 * 獲取yuu 優惠券
 * 获取优惠券成功后，解析完，需要调用
 * @param reLoad 是否重新獲取
 * @return {Promise<AxiosResponse<any>|{msg: string, status: number}|{status: number}|{msg: string, status: number}>}
 */
export const getYuuCoupon = async (reLoad = false) => {
  if(!store.state?.userInfo?.userId){
    COUPON.yuuResult =  {
      status: -1,
      msg: 'ERROR'
    }
    return COUPON.yuuResult
  }
  try {
    const yuuToken = await yuuLogin()
    if(!yuuToken) {
      COUPON.yuuResult = {
        status: -403
      }
      return COUPON.yuuResult
    }
    if (COUPON.yuuResult?.status === 1 && !reLoad) {
      return COUPON.yuuResult
    }
    const paramsYuu = {
      actionName: "candao.member.yuuVoucherGet",
      content: {},
    }
    if (store.state.isYuu){
      paramsYuu.content.yuuGuestKey = store.state?.yuuInfo?.yuuGuestKey || ''
    }
    await sleep(500)
    const res = await postPaas('UserUnify', paramsYuu)
    COUPON.yuuResult = res || {status: -1, msg: 'ERROR: null'}
    return COUPON.yuuResult
  } catch (e) {
    console.error(e)
    COUPON.yuuResult =  {
      status: -1,
      msg: 'ERROR'
    }
    return COUPON.yuuResult
  }
}

/**
 * 獲取yuu券列表 -- 暂不用
 * @param reLoad 是否重新獲取
 * @return {Promise<[]|{length}|*[]>}
 */
// export const getYuuCouponList = async (reLoad = false) => {
//   try {
//     if(COUPON.yuuResult?.status === 1  && !reLoad) {
//       return COUPON.yuu
//     }
//
//     const res = await getYuuCoupon()
//     COUPON.yuuResult = res
//
//     if (COUPON.yuuResult?.status !== 1) {
//       return []
//     }
//
//     COUPON.yuu = deepCopy(res?.data?.data || [])
//     return COUPON.yuu
//
//   } catch (e) {
//     console.error(e)
//   }
// }
// ------------------------------yuu券 END----------------------------------

// ------------------------------KFC券 START----------------------------------
/**
 * 獲取會員優惠券
 * @param reLoad 是否重新獲取
 * @return {Promise<AxiosResponse<any>|{msg: string, status: number}|{msg: string, status: number}>}
 */
 export const getMemberCoupon = async (reLoad = false) => {
  try {
    const {userId} = store.state.userInfo || {}
    if(!userId) {
      COUPON.memberResult = {
        status: -1,
        msg: 'ERROR: No userId'
      }
      return COUPON.memberResult
    }

    if (COUPON.memberResult?.status === 1 && !reLoad) {
      return COUPON.memberResult
    }

    const params = {
      actionName: "candao.member.voucherGet",
      content: {
        voucherStatus: 1,
        userId
      },
    }

    const res = await postPaas('UserUnify', params)
    COUPON.memberResult = res || {status: -1, msg: 'ERROR: null'}
    return COUPON.memberResult
  } catch (e) {
    console.error(e)
    COUPON.memberResult = {
      status: -1,
      msg: 'ERROR'
    }
    return COUPON.memberResult
  }
}
/**
 * 獲取會員優惠券列表 -- 暂不用
 * @param reLoad 是否重新獲取
 * @return {Promise<[]|{length}|*[]>}
 */
// const getMemberCouponList = async (reLoad = false) => {
//   try {
//     if (COUPON.memberResult?.status === 1 && !reLoad) {
//       return COUPON.member
//     }
//
//     const res = await getMemberCoupon()
//     if (res.status !== 1) {
//       return []
//     }
//     COUPON.member = deepCopy(res?.data?.data || [])
//     return COUPON.member
//   } catch (e) {
//     console.error(e)
//     return []
//   }
// }

// ------------------------------KFC券 END----------------------------------

// ------------------------------ 获取/设置券 START----------------------------------
/**
 * 设置优惠券列表 -- 获取到优惠券
 * @param key
 * @param list
 */
export const setMemberCouponList = (key, list) => {
  COUPON[key] = deepCopy(list)
}
/**
 * 异步获取券列表（同时获取 会员券和yuu 券）
 * @return {Promise<void>}
 */
export const getCouponAsync = () => {
  return new Promise(resolve => {
    resetCoupon()

    getMemberCoupon(true)
    getYuuCoupon(true)

    const timeFunc = () => {
      setTimeout(() => {
        const {memberResult, yuuResult} = COUPON
        if(memberResult && yuuResult) {
          resolve(COUPON)
        } else {
          timeFunc()
        }
      }, 100)
    }
    timeFunc()
  })
}

// ------------------------------ 获取/设置券 END----------------------------------

// ------------------------------ 获取券规则 START --------------------------------
let getCouponRulesTime = 0
export const getCouponRules = async () => {
  if (COUPON.rules.length) {
    return COUPON.rules
  }
  // 调用azure接口 获取券规则
  // candao.member.voucherRuleGet
  const params = {
    actionName: 'candao.member.voucherRuleGet',
    content: {}
  }

  let rules = []
  try {
    const res = await postPaas("UserUnify", params)
    rules = res?.data?.data || []
    if (!rules.length && getCouponRulesTime < 3) {
      getCouponRulesTime++
      getCouponRules()
    } else if (getCouponRulesTime > 2) {
      rules = COUPON_RULES
      saveLogger({
        sign: '获取券规则失败，复用前端默认跪着',
        params,
        result: res,
      }, 'error')
    }
  } catch (err) {
    console.error(err)
  }
  // MOCK 规则
  // rules = COUPON_RULES

  rules.forEach(item => {
    item.rule = `${item.voucherType}:${item.haveThreshold ? 1: 0}`
    item.availableVoucherType.forEach(_item => {
      _item.rule = `${_item.voucherType}:${_item.haveThreshold ? 1: 0}`
    })
  })
  COUPON.rules = rules

  return COUPON.rules
}
// ------------------------------ 获取券规则 END --------------------------------

const intersectNoRepeat = data => {
    // return data.reduce((prev, cur) =>{
    //   const _prev = prev.map(item => item.rule)
    //   const _cur = cur.map(item => item.rule)
    //   return Array.from(new Set(_cur.filter((item)=>_prev.includes(item))))
    // })
    let list = []
    data.forEach((item) =>{
      const rules = item.map(item1 => item1.rule)
      if(list.length){
        let selectOkList = rules.filter(m=>list.includes(m)).map(m=>m)
         list =  Array.from(new Set(selectOkList));
      }else{
        list = rules;
      }

    })
    return list;

  }

/**
 * 判断券是否有门槛
 * 3: 赠品券
 * 5: 一口价券
 * @param coupon
 * @return {boolean}
 */
const checkUsage = (coupon = {}) => {
  let isThreshold = false

  const {
    minUsage,  // 门槛金额
    pointsoftExclProducts = [],   // 餐品门槛-例外餐品的餐品映射码
    minUsageProductCode = [] // 餐品门槛-指定餐品的餐品映射码
  } = coupon

  if ([3, 5].includes(coupon.voucherType)) {
    isThreshold = pointsoftExclProducts.length || minUsageProductCode.length || minUsage > 0
  }

  return !!isThreshold
}


const handleFormatResult = (couponList, canFixUseVoucherRule) => {
  const couponGroup = {
    memberGroup: {
      selected: [],
      canFix: [],
      disabled: []
    },
    yuuGroup: {
      selected: [],
      canFix: [],
      disabled: []
    },
    mezzofyCoupon:{
      selected: [],
      canFix: [],
      disabled: []
    }
  }
  couponList.forEach(coupon => {
    if (canFixUseVoucherRule) {
      coupon.canSelected = canFixUseVoucherRule.includes(coupon.voucherRule)
    } else {
      coupon.canSelected = true
    }
    
    const key = coupon.isYuuCouponList ? 'yuuGroup' : coupon.isMezzofy?'mezzofyCoupon':'memberGroup'
    if (coupon.isSelected) {
      couponGroup[key].selected.push(coupon)
    } else if (coupon.canSelected) {
      couponGroup[key].canFix.push(coupon)
    } else {
      couponGroup[key].disabled.push(coupon)
    }

  })

  couponGroup.couponList = deepCopy(couponList)
  return couponGroup
}

/**
 * 格式化 优惠券
 * @param couponList
 * @param selectedIds
 * @return {{memberGroup: {disabled: *[], canFix: *[], selected: *[]}, yuuGroup: {disabled: *[], canFix: *[], selected: *[]}}}
 */
const formatCouponList = ({couponList = [], selectedIds = []}) => {

  // 没有选择优惠券
  if (!selectedIds.length) {

    const group = handleFormatResult(couponList)
    return {
      canFixUseVoucherRule: [],
      selectedCoupon: [],
      ...group
    }
  }


  // 已选的优惠券类型
  const selectedCoupon = []
  couponList.forEach(coupon => {
    // 是否有门门槛
    const isThreshold = checkUsage(coupon)
    coupon.isThreshold = isThreshold
    // 优惠券对应全规则
    coupon.voucherRule = `${coupon.voucherType}:${isThreshold ? 1: 0}`

    if (selectedIds.includes(coupon.voucherCode)) {
      // 添加已选标识
      coupon.isSelected = true
      // 已选优惠券
      selectedCoupon.push(coupon)
    }
  })

  // 已选中的券规则
  let selectedVoucherRule = selectedCoupon.map(coupon => coupon.voucherRule)

  if (selectedVoucherRule.length) {
    // 去重
    selectedVoucherRule = Array.from(new Set(selectedVoucherRule))
  }

  const canFixUseVoucher = COUPON.rules.filter(item => selectedVoucherRule.includes(item.rule)).map(item => item.availableVoucherType)
  const canFixUseVoucherRule = intersectNoRepeat(canFixUseVoucher)
  console.log('已选中的券id', selectedIds)
  console.log('已选中的券规则', selectedVoucherRule)
  console.log('可选中的券规则', canFixUseVoucherRule)

  const group =handleFormatResult(couponList, canFixUseVoucherRule)
  return {
    canFixUseVoucherRule,
    selectedCoupon,
    ...group
  }
}

/**
 * 数据组装
 * @param group
 * @return {*[]}
 */
const concatGroupData = (group = {}) => {
  const {
    selected = [],
    canFix = [],
    disabled= []
  } = group
  return selected.concat(canFix, disabled)
}

/**
 * 优惠计算
 * @param selectedIds 已选会员优惠券id
 * @param newCoupon 新增优惠券 -- 用于判断当前优惠券是否兼容
 * @param selectedList 已选优惠券列表 -- 用于当游客模式下，当没有优惠券列表数据时，传参数替代优惠券列表数据判断互斥
 * @param mezzofyCouponList mezzofy券列表数据 --由于mezzofy券没有券列表数据，所以单独字段传进来计算
 * @return {Promise<{selectedNumber: number, canFixNumber: number, memberCoupon: *[], yuuCoupon: *[]}>} selectedNumber 已选数量  canFixNumber 可叠加数量
 *                          memberCoupon 会员券   yuuCoupon yuu券
 *  @description 返回的券对象新增  isSelected - 是否选中（true 为选中）  canSelected - 是否可选
 */
export const computeCoupon = async ({
  selectedIds = [], // 已选会员优惠券id
                                      newCoupon  = null, // 新增优惠券 -- 用于判断当前优惠券是否兼容
                                      selectedList=[],
                                      mezzofyCouponList = []
                              } = {}) => {
  // const {yuu, member} = COUPON_MOCK
  const {yuu, member} = COUPON
  const yuuCoupon = deepCopy(yuu || [])
  const memberCoupon = deepCopy(member || [])
  // mezzofy券列表数据，用于计算互斥
  const mezzofyList = deepCopy(mezzofyCouponList || [])
  

  // 获取卷规则
  await getCouponRules()

  yuuCoupon.forEach(item => {
    item.isYuuCouponList = true
  })

  const couponList =  memberCoupon.concat(yuuCoupon,mezzofyList)

  let couponListCurren = couponList;
  // 游客模式下，当没有优惠券列表数据时，传参数替代优惠券列表数据判断互斥
  if(selectedList.length&&!couponList.length){
    couponListCurren = selectedList;
  }
  const couponGroup = formatCouponList({couponList:couponListCurren, selectedIds})
  const {yuuGroup, memberGroup,mezzofyGroup} = couponGroup

  const selectedNumber = memberGroup.selected.length + yuuGroup.selected.length
  const canFixNumber = memberGroup.canFix.length + yuuGroup.canFix.length

  const result = {
    selectedNumber,
    canFixNumber,
    memberCoupon: concatGroupData(memberGroup),
    yuuCoupon: concatGroupData(yuuGroup),
    mezzofyCoupon: concatGroupData(mezzofyGroup)
  }
  if (newCoupon) {
    newCoupon = deepCopy(newCoupon)
    const isThreshold = checkUsage(newCoupon)
    newCoupon.isThreshold = isThreshold
    // 优惠券对应全规则
    newCoupon.voucherRule = `${newCoupon.voucherType}:${isThreshold ? 1: 0}`
    const canFixUseVoucherRule = couponGroup.canFixUseVoucherRule || []
    newCoupon.isCanUserVoucher = !selectedIds.length || result.selectedNumber < 1 || canFixUseVoucherRule.includes(newCoupon.voucherRule)
    result.canUseForNewCoupon = newCoupon.isCanUserVoucher
  }
  console.log('返回券结果:', result)
  return result
}

// -----------------------------  計算餐品門檻 START  -----------------------------
export const computeUsage = async ({
                        couponList = [], // 已選餐優惠券
                        shop = {}, // 當前門店信息
                        // productMenu = {}, // 當前餐單
                        reloadYuuStatus = false, // 重新判断yuu 登录态
                        reloadMezzofyStatus = false, // 重新判断mezzofy券是否可用
                      }) => {

  const couponData = {
    disable: [],
    active: []
  }
  couponList = deepCopy(couponList)
  const hasYuu = couponList.find(item => item.isYuuCouponList)
  let isYuuLogin = false
  if (hasYuu&&store.state?.userInfo?.userId) {
    isYuuLogin = await getIsLoginYuu(reloadYuuStatus)
  }
  let useMezzofyCoupon = {}
  // 是否判断mezzofy券是否可用
  if(reloadMezzofyStatus&&store.state?.userInfo?.userId){
    // TO DO 判断mezzofy券是否可用数据处理
    const mezzofyCodeList = (couponList||[]).filter(item => item.isMezzofy).map(m=>m.voucherCode);
    if(mezzofyCodeList&&mezzofyCodeList.length){
      useMezzofyCoupon = await getHoldVoucher(mezzofyCodeList)
    }
  }


  const {cart, H5Seting} = store.state

  let brandId = store.state.currenMappingData.brandId

  const brandInfos = H5Seting?.brandInfos || []
  if (brandInfos.length) {
    brandId = brandInfos[0].brandId
  }

  const hasCoupon3_5 = couponList.find(item => [3, 5].includes(parseInt((item.voucherType || '0'), 10)))

  for (let i = 0; i < couponList.length; i++) {
    const coupon = couponList[i]
    coupon.disable = false
    coupon.disableTips = ''

    // coupon.isCanUserVoucher = false
    coupon.voucherType = parseInt((coupon.voucherType || '0'), 10)

    // 判断是 餐道发行的优惠券  stollect发行的优惠券
    const isCanDao = !(coupon.voucherCalType === null || coupon.isYuuCouponList)

    const {voucherHashtagCode = [], voucherType} = coupon

    if (voucherHashtagCode && voucherHashtagCode.length) {
      // 当前优惠券是否在可用时间范围内
      const timeTag = store.state.time
      if (voucherHashtagCode.indexOf(timeTag) < 0) {
        coupon.disable = true
        coupon.disableTips = '当前优惠券不在可用时间范围内'
        coupon.disableTipId = 3;
        continue
      }
    }

    // 如果有yuu券，则需判断yuu是否登录，未登录则提示清空yuu券
    if (coupon.isYuuCouponList && !isYuuLogin) {
      coupon.disable = true
      coupon.disableTips = 'yuu未登录'
      coupon.disableTipId = 2;
      continue
    }

    // 判断mezzofy券如果不在可用列表里面，则标记状态
    if(reloadMezzofyStatus&&coupon.isMezzofy&&(!useMezzofyCoupon||!useMezzofyCoupon[coupon.voucherCode])){
      coupon.disable = true;
      coupon.disableTips = 'mezzofy券状态已失效'
      coupon.disableTipId = 14;
      console.log('mezzofy券状态已失效')
      continue
    }

    // 当优惠券的类型为 3 5 voucherCalType为空 或者voucherCalType==1 没有金额门槛  时判断购物车是否为空
    if (![3, 5].includes(voucherType)) {
      const {voucherCalType, minUsage} = coupon
      if (!voucherCalType || (parseInt(voucherCalType, 10) === 1 && !minUsage)) {

        if (voucherType === 1 && !hasCoupon3_5 && !cart.length) {
          coupon.disable = true
          coupon.disableTips = '购物车为空'
          coupon.disableTipId = 1;
          continue
        } else if(voucherType !== 1 && !cart.length) {
          coupon.disable = true
          coupon.disableTips = '购物车为空'
          coupon.disableTipId = 1;
          continue
        }
      }
    }

    if (!isCanDao) {
      // 检查stollect发行的优惠券
      // 检查优惠券餐品餐品门槛
      // minUsageProductCode 有值则有门槛需要判断是否满足餐品门槛
      const {minUsageProductCode = []} = coupon
      const len = minUsageProductCode.length
      if (len) {
        const result = await getProductMapping(brandId, minUsageProductCode)
        // 接口失败
        // 餐品门槛会有多个餐品 如果其中一个获取失败则未达到门槛
        if (result.length !== len) {
          coupon.disableTipId = 4;
          coupon.disable = true
          continue
        }

        // 去除贈送餐品
        const _cartProduct = cart.filter(item => !item.isCouponPro)
        // 检查购物车中是否包含门槛餐品 没有则 未达到餐品门槛
        let canSel = !!_cartProduct.find(item => result.includes(item.uid))
         if (!canSel) {
           coupon.disable = true
           coupon.disableTips = '检查stollect发行的优惠券-未达到餐品门槛'
          coupon.disableTipId = 5;
           continue
         }
      }
    } else {
      // 检查餐道优惠券门槛
      // 只判斷餐品門檻
      if (coupon.voucherCalType === 2) {
        const {minUsageProductCode, canDaoProductType, pointsoftExclProducts = [], canDaoExclProductType} = coupon
        let productList = minUsageProductCode || []
        // 1  指定餐品 2 例外餐品
        let minUsageType = 1
        let proType = canDaoProductType

        if ((pointsoftExclProducts && pointsoftExclProducts.length) || canDaoExclProductType) {
          productList = pointsoftExclProducts || []
          proType = canDaoExclProductType
          minUsageType = 2
        }

        const checkRes = await checkPointPro(productList, proType, minUsageType)
        if (!checkRes.usage) {
          coupon.disable = true
          coupon.disableTipId = checkRes.type==1?6:7;
          coupon.disableTips = '检查餐道优惠券门槛-未达到餐品门槛'
          continue
        }
      }
    }

    // 一口价或者赠品券 没有配置对应餐品报错
    if ([3, 5].includes(voucherType) && !coupon.voucherProductCode) {
      coupon.disable = true;
      coupon.disableTipId = 8;
      coupon.disableTips = 'voucherProductCode-没有配置对应餐品报错'
      continue
    }

    //  餐品是否在營業時間/售罄
    if ([3, 5].includes(voucherType)) {
      let isHasPro = false

      const copyBigMenu = PRODUCT_MENU.copy
       for (let i = 0; i < copyBigMenu.length; i++) {
         const smalls = copyBigMenu[i].smalls || []
         for (let s = 0; s < smalls.length; s++) {
           let products = smalls[s].products
           for (let p = 0; p < products.length; p++) {
             if (!isHasPro && products[p].uid === coupon.copyPro.uid) {
               isHasPro = true
               break
             }
           }
         }
       }

      // 餐品是否在營業時間/售罄
      if (!isHasPro || coupon.copyPro.isSoldOut || !coupon.copyPro.isInServiceTime) {
        coupon.disable = true
        coupon.disableTipId = 9;
        if(coupon.copyPro.isSoldOut){
          coupon.disableTipId = 10;
        }else if(!coupon.copyPro.isInServiceTime){
          coupon.disableTipId = 11;
        }
        coupon.disableTips = '餐品不在營業時間/售罄'
        continue
      }
    }

    // 当指定门店的时候判断当前门店是否在指定门店中 不同券商发行的券使用不同的字段来进行判断
    const availableShops = isCanDao ? coupon.canDaoAvailableShops : coupon.availableShops
    const storeIdOrCode = isCanDao ? shop.storeId : shop.extraStoreId
    if (availableShops && availableShops.length && availableShops.indexOf(storeIdOrCode) < 0) {
      coupon.disable = true
      coupon.disableTipId = 12;
      coupon.disableTips = '当前门店不在指定门店中'
    }
  }

  couponList.forEach(coupon => {
    if (coupon.disable) {
      couponData.disable.push(coupon)
    } else {
      couponData.active.push(coupon)
    }
  })
  return couponData
}

/**
 * 格式組裝 獲取可用優惠接口後的優惠券數據
 * @param preMemberPre
 * @param couponList
 * @param disableCoupon
 * @return {{disable: *[], active: *[]}}
 */
export const computeCouponActive = ({
  preMemberPre = [],
  couponList = [],
  disableCoupon = []

                                    }) => {


  const activeIds = preMemberPre.map(item => item.pid)
  const disableList = couponList.filter(item => !activeIds.includes(item.voucherCode.toString()))
  const active = []
  const disable = [...disableCoupon]

  couponList.forEach(coupon => {
    if (disableList.find(item => item.voucherCode === coupon.voucherCode)) {
      coupon.disable = true
      coupon.disableTips = '不满足门槛'
      coupon.disableTipId = 13
      disable.push(coupon)
    } else {
      active.push(coupon)
    }
  })

  return {
    active,
    disable
  }
}

/**
 * 獲取優惠券餐品门槛
 * @param brandId
 * @param minUsageProductCode
 * @return {Promise<*[]>}
 */
const getProductMapping = async (brandId, minUsageProductCode = []) => {
  const params = {
    actionName: 'candao.product.getProductMapping',
    content: {
      posId: 396,
      brandId,
    }
  }
  const result = []
  for (let i = 0; i < minUsageProductCode.length; i++) {
    params.content.mappingCode = minUsageProductCode[i]
    const res = await postDC('Action', params)
    if (res?.status === 1 && res.data) {
      result.push(res.data.mappingId)
    }
  }
  return result
}

// -----------------------------  計算餐品門檻 END  -----------------------------

// -----------------------------  mezzofy券处理 START  -----------------------------
  /**
   * 注意及待处理点
   * 兑换完mezzofy券后，用字段isMezzofy = true 标记为mezzofy券，后续对mezzofy券的处理可以通过该字段区分
   * 解锁券的触发点 
      * 1.清空购物-1
      * 2.删除券（购物车删除券/确认订单页删除券/校验是券不满足规则删除）-1
      * 3.兑换完后不可叠加
    * 判断券是否可叠加规则处理（前端互斥逻辑）-1 
    * 兑换完券后，判断不可使用的每一个条件触发点都需要加解锁
    * 券加入购物车时，需要按从大到小顺序进行排序-1
    * 券码兑换优惠券时，兑换接口换了，返回数据变了，需要处理-1
    * 刷新优惠券时，清空数据不清空mezzofy券数据-1
    * 优惠券列表页的数量和餐单页优惠券角标的已选数量不包括mezzofy券数量-1
    * 兑换接口兑换成功后，则清空输入的兑换码-1
    * 兑换mezzofy券后，点击优惠券列表弹窗，并且刷新清口优惠券列表保存，需保留mezzofy券-1
    * 
    * 未处理内容
    * 翻译文案未提供
    * 判断状态mezzofy券状态接口有问题
    * mezzofy判断状态后的移除判断
    * store 使用saveCouponInfos方法时，对优惠券移除的判断
  */
  /**
   * Mezzofy券解锁 （如果解锁失败，则重试三次）无论是成功还是失败，都不出现提示语
   * @param voucherCodeList //券code列表
   * @return {Promise<*[]>}
   */
  // TO DO 接口对接
   export const releaseHoldVoucher = async (voucherCodeList = [],count=0) => {
    if(!voucherCodeList||!voucherCodeList.length){
      return;
    }
    console.log('mezzofy券解锁',voucherCodeList,count)
    const params = {
      actionName: 'candao.member.voucherRelease',
      content: {
       voucherCodeList
      }
    }
    const res = await postPaas('UserUnify', params)
    if(res.status!=1){
      if(count<3){
        releaseHoldVoucher(voucherCodeList,count+1)
      }else{
        console.log('解锁失败重试')
      }
    }
  }

   /**
   * 查询Mezzofy状态是否可用
   * @param reloadStatus 是否在购物车不存在mezzofy券的情况下请求接口
   * @return {Promise<*[]>}
   */
    export const getHoldVoucher = async (reloadStatus=false) => {
      if(!store.state?.userInfo?.userId){
        return null
      }
      // console.log(voucherCodeList,'判断mezzofy券是否可用')
      const couponList = [...(store.state?.saveCouponInfos || [])]
      // 是否存在Mezzofy券
      // const hasMezzofy= couponList.find(item => item.isMezzofy);
      const mezzofyList= couponList.filter(item => item.isMezzofy).map(m=>m.voucherCode);

      try{
        // return voucherCodeList;
        const params = {
          actionName: 'candao.member.voucherOnHoldGet',
          content: {
          // voucherCodeList
          }
        }
        if(!store.state?.isYuu&&((mezzofyList&&mezzofyList.length)||reloadStatus)){
          const res = await postPaas('UserUnify', params)
          let useCoupon = {};
          let removeList = []
          if(res.status==1&&res?.data?.data){
            res.data.data.forEach(item=>{
              useCoupon[item.voucherCode] = item;
              if(mezzofyList.indexOf(item.voucherCode)<0){
                removeList.push(item.voucherCode);
              }
            })
          }
           // 判断对比查询状态接口有，但是购物车没有的券移除
          if(removeList&&removeList.length){
            // console.log(removeList,'存在多余券')
            releaseHoldVoucher(removeList)
          }
          return useCoupon
        }else{
          return null;
        }
      }catch(err){
        console.error(err)
        return null
      }
    }

// -----------------------------  mezzofy券处理 END  -----------------------------
export const formatMemberPreferentials = ({
                                            couponList = [], // 已選優惠券
  isPay = false,
                                            totalPrice = 0, // 订单金额，isPay=true 必传
                                            fee = 0 // 配送费，isPay=true 外卖 必传
                                          }) => {
  if (!couponList.length) {
    return []
  }

  const voucherTypeInfo = {
    "4": 1,
    "3": 2,
    "1": 3,
    "2": 5,
    "5": 9
  }
  const memberPreferentials = []
  const cart = store.state.cart || []
  const couponProduct = cart.filter(item => item.isCouponPro)
  couponList.forEach(coupon => {
    // const pre = coupon.showPrice
    const _memberPre = {
      childType: 3,
      // yuu和mezzofy只能拿到一种语言文案，当没有另一种语言时，中英文都传同一内容保证字段不为空
      content: coupon.voucherName ||coupon.couponName||coupon.voucherNameEng || coupon.couponNameEng,
      contentEng: coupon.voucherNameEng || coupon.couponNameEng||coupon.voucherName ||coupon.couponName,
      pid: coupon.voucherCode || coupon.cid,
      prePrice: coupon.prePrice || 0,
      realPrePrice: coupon?.realPrePrice || 0,
      count: 1,
      type: 7,
      couponType:0,
      preProducts: [],
      couponIdx: coupon.couponIdx,
      couponPriceLimit: 0,
      couponPriceLimitIncludedMealFee: coupon.includedBoxedMealFee,
      couponPriceLimitType: coupon.canDaoPriceType || 2,
      thirdCoupoChannel:'Candao'
    }
    if(coupon.voucherCalType==null){
      _memberPre.thirdCoupoChannel = 'Storellect'
    }
    if(coupon.isYuuCouponList){
      _memberPre.thirdCoupoChannel = 'YUU'
    }
    if(coupon.isMezzofy){
      _memberPre.thirdCoupoChannel = 'Mezzofy'
    }
    
    if (voucherTypeInfo[coupon.voucherType]) {
      _memberPre.couponType = voucherTypeInfo[coupon.voucherType]
    }

    if ([1, 4].includes(coupon.voucherType)) {
      const prePrice = Number(coupon.voucherAmount) / 100
      if (isPay) {
        let _totalPrice = totalPrice
        if ([1].includes(coupon.voucherType)) {
          _totalPrice += fee
        }

        if (_totalPrice > prePrice) {
          _memberPre.prePrice = -prePrice
        } else {
          _memberPre.prePrice = -_totalPrice
        }
        console.log(_totalPrice, prePrice, _totalPrice > prePrice, _memberPre.prePrice)
      } else {
        _memberPre.prePrice = -prePrice
      }
    } else if ([3, 5].includes(coupon.voucherType)) {
      const {copyPro = {}} = coupon;
      const _product = couponProduct.find(item => item.uid === copyPro.uid && item.voucherCode === coupon.voucherCode)

      if (coupon.voucherAmount) {
        _memberPre.price = Number(coupon.voucherAmount) / 100;
      } else if (coupon.voucherProductCode) {
        _memberPre.price = coupon.mappingPro.mappingPrice;
      }

      let markup = 0
      let product = {}
      if (_product){
        product = deepCopy(_product.selectedFood)
        // _memberPre.price = parseFloat((_product.price + product.markup).toFixed(2))
        markup = product.markup || 0
      } else if (copyPro?.type == 1 && !copyPro.property) {
        product = {
          num: 1,
          pid: copyPro.uid,
          price: copyPro.price,
          typeId: 1,
          weight: 0,
          tagList: copyPro?.tagList || []
        }
      } else if (copyPro?.type === 2) {
        product = {
          pid: coupon.mappingPro.mappingId || 0,
          num: 1,
          price: coupon.mappingPro.mappingPrice || 0,
        }
      }
      // 原价+加价
      product.couponProductOrgPrice = parseFloat((copyPro.price + markup).toFixed(2))
      // 优惠价 + 加价
      product.couponProductPrice = parseFloat((Number(coupon.voucherAmount) / 100 + markup).toFixed(2))
      // product.couponProductPrice = Number(coupon.voucherAmount) / 100 + markup
      if([5].includes(coupon.voucherType) && coupon?.canDaoFixPrice > 0) {
        product.couponProductPrice = (coupon.canDaoFixPrice + markup * 100) / 100
        const prePrice =  product.couponProductPrice - copyPro.price
        _memberPre.prePrice = prePrice * 1 > 0 ? 0: prePrice
      }

      _memberPre.preProducts = [product]
    } else if ([2].includes(coupon.voucherType)) {
      _memberPre.couponDiscount = coupon.voucherDiscount / 100
      _memberPre.couponDiscountType = 1 // 1-订单折扣 2-餐品折扣
    }
    if (coupon.minUsage > 0) {
      _memberPre.couponPriceLimit = coupon.minUsage / 100
    }

    if (isPay) {
      // 提交订单，数组重新组装
      const params = {
        childType: _memberPre.childType,
        content: _memberPre.content,
        contentEng: _memberPre.contentEng,
        pid: _memberPre.pid,
        prePrice: _memberPre.prePrice,
        count: _memberPre.count,
        type: _memberPre.type,
        preProducts: _memberPre.preProducts,
        couponType: _memberPre.couponType
      }

      params.preProducts.forEach(product => {
        delete product.couponProductOrgPrice
        delete product.couponProductPrice

      })
      memberPreferentials.push(params)
    } else {
      memberPreferentials.push(_memberPre)
    }

  })


  console.log('可用优惠接口-memberPreferentials', memberPreferentials)
  return memberPreferentials
}

/**
 * 判断是否是storellect 一口价券
 * @param coupon
 * @return {{isStollect: (0|boolean), voucherAmount: (*|number)}}
 */
export const checkStollect = (coupon = {}) => {
  let isStollect = Number(coupon.voucherType === 3) && (!('voucherCalType' in coupon) || coupon.voucherCalType === null)
  let voucherAmount = coupon.voucherAmount || 0
  const voucherProductCode = coupon.voucherProductCode[0]
  if (isStollect && voucherProductCode) {
    const copyMenu = PRODUCT_MENU.copy || []
    // 餐单餐品是否有价格
    let hasPrice = false
    copyMenu.forEach(big=> {
      if (!hasPrice) {
        big.smalls.forEach(small => {
          if (!hasPrice) {
            const product = small.products.find(item => voucherProductCode === item.extId)
            console.log(`storellect券【${coupon.voucherId}】-餐单餐品【映射码：${voucherProductCode}】价格`, product?.price)
            if (product) {
              const price = product.price || 0
              hasPrice = price > 0
              voucherAmount = price * 100
            }
          }
        })
      }
    })
    isStollect = hasPrice
  }
  return {isStollect, voucherAmount}
}
/**
 * Stollect 特殊处理
 * vouchertype=3时且voucherCalType为空且餐单餐品有价格，则视为storellect券的一口价券，转为vouchertype=5处理
 * @param couponList
 * @return {{length}|*}
 */
const handleCouponForStollect = (couponList) => {
  if (!couponList.length) {
    return couponList
  }
  couponList.forEach(item => {
    if (Number(item.voucherType === 3)) {
      const {isStollect: _isStollect, voucherAmount} = checkStollect(item)
      if (_isStollect) {
        item.voucherType = 5
        item.voucherAmount = voucherAmount || 0
      }else{
        if(!('voucherCalType' in item) || item.voucherCalType === null){
          item.voucherAmount = 0
        }
      }

    }
  })
  return couponList
}


// 过滤处理优惠券数据
export const filterList = (couponData={},businessType=1,shopInfo,keyVal='memberResult') =>{
  let couponRes = deepCopy(couponData)
  if(couponRes?.isFilter){
    console.log(couponRes,'couponRes')
    return couponRes;
  }
  if(couponRes&&couponRes?.status == 1){
    let orderTypeCode;
    if (businessType == 1) {
      orderTypeCode = "A02";
    } else if (businessType == 2) {
      orderTypeCode = "A01";
    } else if (businessType == 3) {
      orderTypeCode = "A05";
    }
    var couArr = [];
    // let nowTime = this.pageParam.book == 2 ? getTimestampByDate(this.second) : this.SERVER_TIME
    let nowTime = couponRes.serverTime;
    let couponList = couponRes?.data?.data||[];
    // 如果为yuu券
    if(keyVal&&keyVal=='yuuResult'){
      couponList = couponRes.data.data.map(item=>{
        // 添加标记参数是否yuu券
        item.isYuuCouponList = true;
        return item;
      })

    }
    let couponListInfo = [...couponList];


    for (var i = 0; i < couponListInfo.length; i++) {
      // var nowTime = this.book == 2 ? getTimestampByDate(this.second) : this.SERVER_TIME
      var startTime = 0;
      if (couponListInfo[i].validBeginDate) {
        var beginTimeArr = couponListInfo[i].validBeginDate.split("T"); //优惠券 有效期开始时间
        var beginDate = beginTimeArr[0].split("-");
        var beginTime = beginTimeArr[1].split(".")[0];
        var begin =
          beginDate[0] +
          "/" +
          beginDate[1] +
          "/" +
          beginDate[2] +
          " " +
          beginTime;
        startTime = getTimestampByDate(begin)
      }
      var endTime = 0;
      if (couponListInfo[i].validEndDate) {
        //优惠券有效期结束时间
        var endTimeArr = couponListInfo[i].validEndDate.split("T");
        var endDate = endTimeArr[0].split("-");
        var endTimeTe = endTimeArr[1].split(".")[0];
        var end =
          endDate[0] +
          "/" +
          endDate[1] +
          "/" +
          endDate[2] +
          " " +
          endTimeTe;
        endTime = getTimestampByDate(end)
      } else {
        //如果此值为空 表示无限期有效
        endTime = nowTime + 100000;
      }
      if (nowTime > startTime && nowTime < endTime) {
        // 过滤优惠券
        // 优惠券可用的业务类型
        if (couponListInfo[i].voucherCategoryCode) {
            // 强制登录需求，pd确定去掉游客模式下业务类型的过滤
          if (
            couponListInfo[i].voucherCategoryCode.indexOf(orderTypeCode) >
            -1 ||
            !store.state.userInfo||
            couponListInfo[i].voucherCategoryCode.length == 0
          ) {
            if(!store.state.userInfo&&!store.state?.isYuu){
              couArr.push(couponListInfo[i]);
            }else if (
              couponListInfo[i].voucherType == 1 ||
              couponListInfo[i].voucherType == 4 ||
              couponListInfo[i].voucherType == 2 ||
              couponListInfo[i].voucherType == 3 ||
              couponListInfo[i].voucherType == 5
            ) {
              couArr.push(couponListInfo[i]);
            }
           
          }
        } else {
          //如果此值为空 表示通用
          couArr.push(couponListInfo[i]);
        }
      }
    }
    console.log(JSON.parse(JSON.stringify(couArr)),'couArr')
    var couponArr = [];
    var timeTag = store.state.time; //当前时间段 早餐 午餐 下午茶 晚餐
    for (var j = 0; j < couArr.length; j++) {
      // 判断时餐道发行的优惠券还是 stollect发行的优惠券
      let isCandao = true;
      if (couArr[j].voucherCalType == null||couArr[j].isYuuCouponList) {
        isCandao = false;
      }
      // 不同券商发行的优惠券使用不同的字段判断当前门店是否可用
      let availableShops = isCandao
        ? couArr[j].canDaoAvailableShops
        : couArr[j].availableShops;
      let storeIdOrCode = isCandao
        ? shopInfo.storeId
        : shopInfo.extraStoreId;
      if (
        availableShops &&
        availableShops.length > 0 &&
        availableShops.indexOf(storeIdOrCode) < 0
      ) {
        //如果当前门店不可用则跳过
        continue;
      }
      if (
        couArr[j].voucherHashtagCode &&
        couArr[j].voucherHashtagCode.length
      ) {
        // 当前优惠券是否在可用时间范围内
        if (couArr[j].voucherHashtagCode.indexOf(timeTag) > -1) {
          if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
            // 不同优惠券对应不同的字段 这个字段必须有值当前优惠券才可用
            if (
              couArr[j].voucherProductCode &&
              couArr[j].voucherProductCode.length
            ) {
              couponArr.push(couArr[j]);
            }
          } else if (
            couArr[j].voucherType == 1 ||
            couArr[j].voucherType == 4
          ) {
            if (couArr[j].voucherAmount) {
              couponArr.push(couArr[j]);
            }
          } else if (
            couArr[j].voucherType == 2 &&
            couArr[j].voucherDiscount
          ) {
            couponArr.push(couArr[j]);
          }else{
            // 游客模式返回的优惠券无voucherType类型
            if(!store.state.userInfo&&!store.state?.isYuu){
              couponArr.push(couArr[j]);
            }
          }
        }
      } else {
        // 此值为空表示不分时间段通用
        // 不同优惠券对应不同的字段 这个字段必须有值当前优惠券才可用
        if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
          if (
            couArr[j].voucherProductCode &&
            couArr[j].voucherProductCode.length
          ) {
            couponArr.push(couArr[j]);
          }
        } else if (
          couArr[j].voucherType == 1 ||
          couArr[j].voucherType == 4
        ) {
          if (couArr[j].voucherAmount) {
            couponArr.push(couArr[j]);
          }
        } else if (
          couArr[j].voucherType == 2 &&
          couArr[j].voucherDiscount
        ) {
          couponArr.push(couArr[j]);
        }else{
          // 游客模式返回的优惠券无voucherType类型
          if(!store.state.userInfo){
            couponArr.push(couArr[j]);
          }
        }
      }
    }
    // 拆分拼接优惠可用日期
    for (var z = 0; z < couponArr.length; z++) {
      var beginDateC = couponArr[z].validBeginDate //开始时间
        ? couponArr[z].validBeginDate.split("T")[0].split("-").reverse()
        : null;
      var endDateC = couponArr[z].validEndDate //过期时间
        ? couponArr[z].validEndDate.split("T")[0].split("-").reverse()
        : null;
      if (beginDateC) {
        couponArr[z].date =
          // beginDateC.join("-") + "至" + endDateC.join("-");
    endDateC.join("/");
      } else if (endDateC) {
        couponArr[z].date = endDateC.join("/");
      } else {
        couponArr[z].date = "-";
      }
      couponArr[z].couTag = [];
      // 不同的优惠券对应的业务类型 对应自己的标签
      // 根据voucherCategoryCode换成餐单对应业务类型

      // 获取对应支持的业务类型
      couponArr[z].couTag = getVoucherCategoryCode(couponArr[z].voucherCategoryCode)
      if (
        couponArr[z].voucherCategoryCode &&
        (couponArr[z].voucherCategoryCode.indexOf("A01") > -1 ||
          couponArr[z].voucherCategoryCode.indexOf("A02") > -1)
      ) {
        couponArr[z].sendFunc = 1; //外送
      } else if (
        couponArr[z].voucherCategoryCode &&
        (couponArr[z].voucherCategoryCode.indexOf("A03") > -1 ||
          couponArr[z].voucherCategoryCode.indexOf("A05") > -1)
      ) {
        couponArr[z].sendFunc = 2; //堂食
      } else {
        couponArr[z].sendFunc = 0; //通用
      }
    }

    // if  (keyVal === 'memberResult') {
    // YUU 也是同storellect一样，一口价给3
    couponArr = handleCouponForStollect(couponArr)
    // }

    couponRes.data.data = couponArr;
    // 标记数据已经经过过滤
    couponRes.isFilter = true;
    couponData.isFilter = true;
    if(keyVal){
      setMemberCouponList(keyVal,couponRes)//memberResult yuuResult
      if(keyVal=='memberResult'){
       setMemberCouponList('member',couponArr||[])
      }else{
       setMemberCouponList('yuu',couponArr||[])
      }
    }
    return couponRes;
  } else {
    return couponRes;
  }
}

// 优惠券获取对应支持的业务类型
export const getVoucherCategoryCode = (voucherCategoryCode) => {
  let voucherCategoryCodeObj = {
    "A01":1,
    "A02":2,
    "A03":3,
    "A05":5
  }
  let couTag = []

  if(voucherCategoryCode&&voucherCategoryCode.length){
    couTag = voucherCategoryCode.filter(m=>voucherCategoryCodeObj[m])
                                .map(n=>voucherCategoryCodeObj[n]);
  }
  return couTag;
}
